<template>
  <gallery-modal @onClose="toggleGallery" @onAdded="onAdded" :show="showGallery" />

	<div @dragover.prevent @drop="onDrop" @click="$refs.file.click()" class="area text-center p-4">
		<h5>{{ title }}</h5>
		<small>{{ subtitle }}</small>
    <br>
    <button type="button" class="btn-info" @click.stop="toggleGallery">Gallery</button>
		<div class="container-fluid mt-4">
			<div class="row">
				<div v-for="(image, index) in images" :key="index" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-2">
					<button type="button" class="btn-close" @click.stop="removeImage(index)">
						<span>&times;</span>
					</button>
					<img :src="image" @click.stop class="image-preview">
				</div>
				<input @change="onUpload" type="file" ref="file" class="d-none" multiple>
			</div>
		</div>
		<p v-if="errorText" class="text-danger">{{ errorText }}</p>
	</div>
</template>

<script>

import request from "@/request";
import consti from '@/const'
import GalleryModal from "./GalleryModal.vue";

export default {
	name: "DragAndDrop",
  components: {GalleryModal},
	props: {
		title: {
			type: String,
			required: true
		},
		subtitle: {
			type: String,
			required: true
		},
		images: {
			type: Array,
			required: true
		},
		count: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			errorText: null,
			loading: false,
      showGallery: false,
			consti
		}
	},
	methods: {
		onDrop(e) {
			e.stopPropagation()
			e.preventDefault()

			let files = e.dataTransfer.files

			this.processFiles(files)
		},
		onUpload(e) {
			e.preventDefault()

			let files = e.target.files

			this.processFiles(files)
		},
    onAdded(images) {
      this.images.length = 0
      images.forEach(img => this.images.push(img))
      this.toggleGallery()
    },
    toggleGallery() {
      this.showGallery = !this.showGallery
    },
		processFiles(files) {
			this.errorText = null

			if (files.length + this.images.length > this.count) {
				this.errorText = `Only ${this.count} image(s) allowed`
				return
			}

			for (let i = 0; i < files.length; ++i) {
				if (!files[i].type.match('image.*')) {
					this.errorText = 'File is not an image'
					return
				}
			}

			let formData = new FormData();

			for (let i = 0; i < files.length; ++i) {
				formData.append('files[]', files[i])
			}

			this.loading = true

			let _this = this

			request.submitFormData(consti.baseUrl + consti.upload, formData, {
				'Content-Type': 'multipart/form-data'
			}, this.$router)
				.then(data => {
					this.loading = false

					for (let i = 0; i < data.length; ++i) {
						_this.images.push(data[i])
					}
				}, error => {
					this.loading = false
				})
		},
		removeImage(index) {
			this.errorText = null
			this.images.splice(index, 1)
		}
	}
}
</script>

<style scoped>

.area {
	border: 4px dashed gray;
	cursor: pointer;
	background-color: lightgrey;
}

.image-preview {
	height: 60px;
	width: 100px;
}

.btn-close {
	position: absolute;
}

</style>
