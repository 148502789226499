<template>
	<button class="btn btn-primary" @click="showCreateModal = !showCreateModal">Create one</button>
	<create-post-modal @onClose="closeModal" @onCreated="postCreated" :show="showCreateModal"></create-post-modal>

	<div v-if="loading" class="d-flex justify-content-center mt-5">
		<div class="spinner-border" role="status">
			<span class="sr-only">Loading...</span>
		</div>
	</div>

	<div v-else class="container-fluid">
		<div class="row">
			<div v-for="(post, index) in posts" :key="index" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
				<post-card :id="post.id"
				           :title="post.title"
				           :short-description="post.description"
				           :cover-image-url="post.cover_image"
                   :published="post.published"
        />
			</div>
		</div>
	</div>
</template>

<script>

import PostCard from '@/components/PostCard'
import request from '@/request'
import consti from '@/const'
import CreatePostModal from "../components/CreatePostModal"

export default {
	name: "Posts",
	components: {CreatePostModal, PostCard},
	created() {
		this.fetch()
	},
  mounted() {
    document.title = 'BMS | Posts'
  },
	data() {
		return {
			loading: true,
			showCreateModal: false,
			posts: []
		}
	},
	methods: {
		fetch() {
			request.get(consti.baseUrl + consti.posts, this.$router)
				.then(data => {
					this.loading = false
					this.posts = data
				}, error => {
					this.loading = false
				})
		},
		postCreated() {
			this.closeModal()
			this.fetch()
		},
		closeModal() {
			this.showCreateModal = !this.showCreateModal
		}
	}
}
</script>

<style scoped>

</style>
