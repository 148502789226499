<template>
  <div class="d-flex card">
    <div class="cover-image" :style="coverImageStyle"></div>
    <div class="card-body d-flex flex-column p-0 pt-2">
      <h5 class="card-title">{{ title }}</h5>
      <p class="card-text">{{ shortDescription.slice(0, 100) }}...</p>
      <p v-if="published" class="post-status">Status: <span class="text-success">Published</span></p>
      <p v-else class="post-status">Status: <span class="text-danger">Draft</span></p>

      <div class="mt-auto">
        <a :href="'posts/' + id + '/edit'" type="button" class="btn btn-info">
          <i class="bi bi-pencil-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'PostCard',
    props: {
      id: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      shortDescription: {
        type: String,
        required: true
      },
      published: {
        type: Boolean,
        required: true
      },
      coverImageUrl: {
        type: String,
        required: false
      }
    },
    computed: {
      coverImageStyle() {
        let url = this.coverImageUrl ?? require('../assets/default-blur.jpg')

        return {
          'background': 'url("' + url + '")'
        }
      }
    },
    data() {
      return {
        loading: false
      }
    }
  }
</script>

<style scoped>

  .cover-image {
    background-position: center;
    background-size: cover;
    height: 120px;
  }

  .card {
    width: 200px;
    padding: 1rem;
    margin: 1rem;
    display: inline-block;
    cursor: pointer;
  }

  .card:hover {
    background-color: #cfdeec;
  }

  .card-title {
	  font-size: 1rem;
  }

  .card-text {
    font-size: .5rem;
  }

  .post-status {
    text-align: left;
  }
</style>
