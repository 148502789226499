<template>
	<div v-if="show" :key="show" class="modal pt-4">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title text-danger">Create a new post</h5>
					<button @click="close" class="close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<input v-model="title" class="form-control" placeholder="Title here..." required>
					</div>
					<div class="form-group">
						<input v-model="description" class="form-control" placeholder="Short description here..." required>
					</div>
                    <div class="form-group">
	                    <select v-model="category" class="form-control" required>
		                    <option disabled selected :value="null">Pick the category</option>
		                    <option v-for="category in categories" :value="category.name">{{ category.name }}</option>
	                    </select>
                    </div>
					<div class="form-group">
						<drag-and-drop
							title="Drop the cover image here!"
							subtitle="One image only."
							:images="images"
							count="1"
						/>
					</div>
				</div>
				<div class="modal-footer">
					<button @click="create" v-bind:disabled="!validated" type="button" class="btn btn-primary">
						<span v-if="loading" class="spinner-border spinner-border-sm"></span>
						Create
					</button>
					<button @click="close" type="button" class="btn btn-primary">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import consti from '@/const'
import request from '@/request'
import DragAndDrop from "./DragAndDrop";

export default {
	name: "CreatePostModal",
	components: {DragAndDrop},
	emits: ['onClose', 'onCreated'],
	props: {
		show: {
			type: Boolean,
			required: true
		}
	},
	created() {
		this.getCategories()
	},
	computed: {
		validated() {
			return this.title && this.category && this.description && !this.loading
		}
	},
	data() {
		return {
			title: null,
			category: null,
			description: null,
			coverImage: null,
			categories: [],
			loading: false,
			images: []
		}
	},
	methods: {
		getCategories() {
			this.loading = true
			request.get(consti.baseUrl + consti.categories, this.$router)
				.then(data => {
					this.loading = false
					this.categories = data
				})
		},
		close() {
			this.$emit('onClose')
			this.resetData()
		},
		create() {
			let formData = new FormData()

			formData.append('title', this.title)
			formData.append('category', this.category)
			formData.append('description', this.description)

			this.coverImage = this.images.length > 0 ? this.images[0] : null

			if (!!this.coverImage) {
				formData.append('cover_image', this.coverImage)
			}

			this.loading = true
			request.submitFormData(consti.baseUrl + consti.posts, formData, {
				'Content-Type': 'multipart/form-data'
			}, this.$router)
				.then(data => {
					this.$emit('onCreated')
					this.loading = false
				}, error => {
					this.loading = false
				})
		},
		resetData() {
			this.title = null
			this.category = null
			this.description = null
			this.images = []
		}
	}
}
</script>

<style scoped>

.modal {
	display: block;
	background-color: rgba(0, 0, 0, 0.3);
}

</style>
