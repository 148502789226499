<template>
  <div v-if="show" :key="show" class="modal pt-4">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-danger">Gallery</h5>
          <button @click="close" class="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span v-if="loading" class="spinner-border spinner-border-sm"></span>
          <div class="gallery">
            <div v-for="(image, index) in images" :key="index" class="image-container">
              <div v-if="selectedImages.includes(image)" class="check-icon-container">
                <i class="bi bi-check-circle-fill"></i>
              </div>
              <img class="img-thumbnail gallery-image" @click="selectImage" :src="image" alt="">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="add" v-bind:disabled="!validated" type="button" class="btn btn-primary">
            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
            Add
          </button>
          <button @click="close" type="button" class="btn btn-primary">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DragAndDrop from "./DragAndDrop.vue"
import consti from '@/const'
import request from '@/request'

export default {
  name: "GalleryModal",
  components: {DragAndDrop},
  emits: ['onClose', 'onAdded'],
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal) {
        this.getImages()
      }
    }
  },
  computed: {
    validated() {
      return this.images && !this.loading
    }
  },
  data() {
    return {
      images: [],
      selectedImages: [],
      loading: false
    }
  },
  methods: {
    selectImage(e) {
      const image = e.target.currentSrc

      if (this.selectedImages.includes(image)) {
        this.selectedImages = this.selectedImages.filter(element => element !== image)
      } else {
        this.selectedImages.push(image)
      }
    },
    getImages() {
      this.loading = true
      request.get(consti.baseUrl + consti.gallery, this.$router)
        .then(data => {
          this.loading = false
          this.images = data.images
        })
    },
    close() {
      this.$emit('onClose')
      this.resetData()
    },
    add() {
      this.$emit('onAdded', this.selectedImages)
      this.resetData()
    },
    resetData() {
      this.selectedImages = []
    }
  }
}

</script>

<style scoped>

.gallery {
  overflow: auto;
  height: 60vh;
}

.gallery-image {
  cursor: pointer;
  max-height: 100px;
}

.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
}

.image-container {
  position: relative;
  display: inline-block;
}

.check-icon-container {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 24px;
  color: green;
}

</style>
